<template>
  <div class="main__content">
    <b-card
      no-body
      class="p-1"
    >
      <Billing
        :select-enabled="false"
        :dates-lists="datesLists"
        :data-lists="dataLists"
        :summary="summary"
        :is-my-billing="true"
        @onGetDates="onGetDates"
        @onGetData="onGetData"
      />
    </b-card>
  </div>
</template>

<script>
import Billing from '@/components/billing/Billing.vue'

export default {
  components: {
    Billing,
  },
  data() {
    return {
      datesLists: [],
      dataLists: [],
      summary: {},
    }
  },
  methods: {
    async onGetDates() {
      try {
        const { data } = await this.$http.get('/billing/getDates')
        const dates = data?.data?.dates ?? []
        this.datesLists = dates
      } catch (error) {
        this.datesLists = []
      }
    },

    async onGetData(params) {
      const { cb } = params

      try {
        const weekNumber = params.selectedDate
        // const { data } = await this.$http.get(`/reports/getReportMyBillingByWeek/${weekNumber}`)
        const { data } = await this.$http.get(`/billing/v2/getReportMyBillingByWeek/${weekNumber}`)
        this.dataLists = data.data.lists
        this.summary = data.data.summary
      } catch (error) {
        this.dataLists = []
        this.summary = {}
      } finally {
        cb()
      }
    },
  },
}
</script>

<style>

</style>
